/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/front2.jpeg";
import { useTranslation } from "react-i18next";
import MKButton from "components/MKButton";


function About() {
    const { t } = useTranslation();
    return (
        <>
            <DefaultNavbar
                routes={routes}
                sticky={true}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "default",
                }}
                transparent
                light
            />
            <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        flexDirection="column"
                        sx={{ textAlign: "left" }}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                        >
                            {t("Presentation.title")}
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Grid
                sx={{
                    mb: 4,
                    mt: 6
                }}
            >
                <Container>

                    <MKTypography variant="h4" mb={2}>
                        {t("Presentation.part.1.title")}
                    </MKTypography>
                    <MKTypography variant="body2" mb={2}>
                        {t("Presentation.part.1.text.1")}
                    </MKTypography>
                    <MKTypography variant="body2" >
                        {t("Presentation.part.1.text.2")}
                    </MKTypography>
                    <ul>
                        <li>
                            <MKTypography variant="body2" color="black">
                                {t("Presentation.part.1.text.3.title")}
                            </MKTypography>
                            <MKTypography variant="body2" mb={2}>
                                {t("Presentation.part.1.text.3.text")}
                            </MKTypography>
                        </li>
                        <li>
                            <MKTypography variant="body2" color="black">
                                {t("Presentation.part.1.text.4.title")}
                            </MKTypography>
                            <MKTypography variant="body2">
                                {t("Presentation.part.1.text.4.text")}
                            </MKTypography>
                        </li>
                    </ul>

                    <MKTypography variant="h4" mb={1}>
                        {t("Presentation.part.2.title")}
                    </MKTypography>
                    <MKTypography variant="body2" mb={2}>
                        {t("Presentation.part.2.text")}
                    </MKTypography>

                    <MKTypography variant="h4" mb={1}>
                        {t("Presentation.part.3.title")}
                    </MKTypography>
                    <MKTypography variant="body2" mb={2}>
                        {t("Presentation.part.3.text")}
                    </MKTypography>

                    <MKTypography variant="h4" mb={1}>
                        {t("Presentation.part.4.title")}
                    </MKTypography>
                    <ul>
                        <li>
                            <MKTypography variant="body2">
                                {t("Presentation.part.4.text.1")}
                            </MKTypography>
                        </li>
                        <li>
                            <MKTypography variant="body2">
                                {t("Presentation.part.4.text.2")}
                            </MKTypography>
                        </li>
                        <li>
                            <MKTypography variant="body2">
                                {t("Presentation.part.4.text.3")}
                            </MKTypography>
                        </li>
                    </ul>
                    <Grid sx={{ textAlign: "center" }}>
                        <MKButton
                            variant="contained"
                            color="primary"
                            size="large"
                            component="a"
                            href="/fichier-national-travailleurs-etrangers-cameroun"
                            sx={{ mb: 2 }}
                        >
                            {t("Services.2")}
                        </MKButton>
                    </Grid>
                </Container>
            </Grid>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default About;


import bgImage from "assets/images/reunion.jpg";
import law1 from "assets/files/1.pdf";
import law2 from "assets/files/2.pdf";
import law3 from "assets/files/3.pdf";
import law4 from "assets/files/4.pdf";
import law5 from "assets/files/5.pdf";
import law6 from "assets/files/6.pdf";
import law7 from "assets/files/7.pdf";

export default [
    {
        id: 1,
        title: {
            en: "DECREE N° 93/571/PM",
            fr: "DECRET N° 93/571/PM"
        },
        text: {
            en: "DECREE No. 93/571/PM OF JULY 15, 1993 SETTING THE CONDITIONS OF EMPLOYMENT OF FOREIGN NATIONAL WORKERS",
            fr: "DECRET N° 93/571/PM DU 15 JUILLET 1993 FIXANT LES CONDITIONS D’EMPLOI DES TRAVAILLEURS DE NATIONALITE ETRANGERE"
        },
        image: bgImage,
        date: "15 Jul 1993",
        file: law1
    },
    {
        id: 2,
        title: {
            en: "LAW N°2022/020",
            fr: "LOI N°2022/020"
        },
        text: {
            en: "LAW N°2022/020 OF DEC 27, 2022 PROVIDING FINANCE LAW OF THE REPUBLIC OF CAMEROON FOR THE FINANCIAL YEAR 2023",
            fr: "LOI N°2022/020 DU 27 DEC 2022 PORTANT LOI DE FINANCES DE LA REPUBLIQUE DU CAMEROUN POUR L’EXERCICE 2023"
        },
        image: bgImage,
        date: "25 Dec 2022",
        file: law2
    },
    {
        id: 3,
        title: {
            en: "ORDER N° 0509/ MINFI",
            fr: "ARRETE N° 0509/ MINFI"
        },
        text: {
            en: "ORDER N° 0509/ MINFI OF APRIL 20, 2023 SPECIFYING THE PRACTICAL ARRANGEMENTS FOR IMPLEMENTING THE REVENUE RESULTING FROM THE APPOSITION OF THE VISA ON THE CONTRACTS OF WORKERS OF FOREIGN NATIONALITY.",
            fr: "ARRETE N° 0509/ MINFI DU 20 AVRIL 2023 PRECISANT LES MODALTES PRATIQUES DE MISE EN OEUVRE DES RECETTES ISSUES DE L’APPOSITION DU VISA SUR LES CONTRATS DES TRAVAILLEURS DE NATIONALITE ETRANGERE."
        },
        image: bgImage,
        date: "20 Avr 2023",
        file: law3
    },
    {
        id: 4,
        title: {
            en: "CIRCULAR LETTER No. 000001/LC/MINEFOP/SG/DRMO",
            fr: "LETTRE CIRCULAIRE N° 000001/LC/MINEFOP/SG/DRMO"
        },
        text: {
            en: "CIRCULAR LETTER No. 000001/LC/MINEFOP/SG/DRMO of JAN 06, 2023 specifying the terms of application of article twenty-second of the law relating to the finance law of the Republic of Cameroon for the financial year 2023 relating to the 'institution of a levy for work visa fees affixed to the employment contracts of workers of foreign nationality.",
            fr: "LETTRE CIRCULAIRE N° 000001/LC/MINEFOP/SG/DRMO du 06 JAN 2023 précisant les modalités d’application de l’article vingt-deuxieme de la loi portant loi des finances de la République du Cameroun pour l’ exercice 2023 relative à l’institution d’ un prélèvement au titre des frais de visa de travail apposé sur les contrats de travail des travailleurs de nationalité étrangère."
        },
        image: bgImage,
        date: "06 Jan 2023",
        file: law4
    },
    {
        id: 5,
        title: {
            en: "CIRCULAR LETTER No. 000002/ LC/MINEFOP/DRMO/SC",
            fr: "LETTRE CIRCULAIRE N° 000002/ LC/MINEFOP/DRMO/SC"
        },
        text: {
            en: "CIRCULAR LETTER No. 000002/ LC/MINEFOP/DRMO/SC of FEB 03, 2023 Relating to the terms and conditions for obtaining a visa for the employment contracts of a worker of foreign nationality holding a temporary visa.",
            fr: "LETTRE CIRCULAIRE N° 000002/ LC/MINEFOP/DRMO/SC du 03 FEV 2023 Relative aux modalités d’obtention du visa des contrats de travail d’un  travailleur de nationalité etrangère détenteur d’un visa temporaire."
        },
        image: bgImage,
        date: "03 Fev 2023",
        file: law5
    },
    {
        id: 6,
        title: {
            en: "CIRCULAR LETTER N° 000005 / LC/MINEFOP/DRMO/SDIA",
            fr: "LETTRE CIRCULAIRE N° 000005 / LC/MINEFOP/DRMO/SDIA"
        },
        text: {
            en: "CIRCULAR LETTER N° 000005 / LC/MINEFOP/DRMO/SDIA of OCT 13, 2023 Relating to the recruitment of Human Resources Directors in production units based in Cameroon.",
            fr: "LETTRE CIRCULAIRE N° 000005 / LC/MINEFOP/DRMO/SDIA du 13 OCT 2023 Relative au recrutement des Directeurs des Ressources Humaines dans les  unités de production basées au Cameroun."
        },
        image: bgImage,
        date: "13 Oct 2023",
        file: law6
    },
    {
        id: 7,
        title: {
            en: "CIRCULAR LETTER No. 000001/LC/MINEFOP/DRMO/SDIA",
            fr: "LETTRE CIRCULAIRE N° 000001/LC/MINEFOP/DRMO/SDIA"
        },
        text: {
            en: "CIRCULAR LETTER No. 000001/LC/MINEFOP/DRMO/SDIA of JAN 15, 2024 relating to the terms of recruitment of personnel of foreign nationality in Cameroon.",
            fr: "LETTRE CIRCULAIRE N° 000001/LC/MINEFOP/DRMO/SDIA du 15 JAN 2024 relatif aux modalités de recrutement des personnels de nationalité étrangère au Cameroun."
        },
        image: bgImage,
        date: "15 Jan 2024",
        file: law7
    }
];
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/front2.jpeg";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Icon } from "@mui/material";
import MKButton from "components/MKButton";
import newsData from "pages/Presentation/sections/data/newsData";


function DetailNews() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = location.state;

    return (
        <>
            <DefaultNavbar
                routes={routes}
                sticky={true}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "default",
                }}
                transparent
                light
            />
            <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        flexDirection="column"
                        sx={{ textAlign: "left" }}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                        >
                            {data?.title[i18n.language]}
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Grid
                sx={{
                    p: 2,
                    mb: 4,
                }}
            >
                <Container>
                    <Grid container xs={12} md={12} spacing={3} justifyContent="left" textAlign="left">
                        <Grid item xs={12} sm={6} lg={9}>
                            <MKBox
                                width="80%"
                                height="50vh"
                                borderRadius="lg"
                                mt={2}
                                sx={{
                                    backgroundImage: `url(${data?.image})`,
                                    backgroundSize: 'contain', // Utilisez 'contain' pour afficher toute l'image sans la découper
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat' // Empêche la répétition de l'image
                                }}
                            />
                            <MKTypography
                                variant="h3"
                            >
                                {data?.title[i18n.language]}
                            </MKTypography>
                            <MKTypography
                                variant="caption"
                            >
                                <Icon>event</Icon>&nbsp;
                                {data?.date}
                            </MKTypography>
                            <MKTypography
                                variant="body2" style={{ marginTop: 15, marginBottom: 15 }}
                            >
                                {data?.text[i18n.language]}
                            </MKTypography>
                            <MKButton
                                onClick={async (e) => {
                                    e.preventDefault();

                                    try {
                                        await navigator.share({
                                            title: data?.title[i18n.language],
                                            text: data?.text[i18n.language],
                                            url: window.location.href
                                        });
                                    } catch (e) {
                                        console.log('API de partage non disponible');
                                    }
                                }}
                                rel="noreferrer"
                                variant="contained"
                                size="lg"
                                color="warning">
                                {"Partager"}&nbsp;
                                <Icon>share</Icon>
                            </MKButton>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{ position: 'relative' }}>
                            {/* <Box
                                sx={{
                                    position: 'absolute',
                                    top: 35,
                                    height: "50%",
                                    bottom: 0,
                                    left: 0, // Positionner la ligne à gauche
                                    borderLeft: '2px solid grey', // Définit la ligne verticale
                                }}
                            /> */}
                            <MKTypography
                                variant="h3"
                                sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    '::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '50%',
                                        height: '3px',
                                        backgroundColor: '#FCE410',
                                        transform: 'translateX(-95%)',
                                    },
                                }}
                            >
                                {t("Details.News.recent")}
                            </MKTypography>
                            <Grid mt={2}>
                                {
                                    newsData.slice(0, 3).map((item) => (
                                        <Grid key={item?.id} >
                                            <MKTypography
                                                variant="body1"
                                            >
                                                {item?.title[i18n.language]}
                                            </MKTypography>

                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                mb: 2,
                                                alignItems: "center",  // Align vertically in the center
                                            }}>
                                                <MKTypography
                                                    variant="caption"
                                                >
                                                    {item?.date}
                                                </MKTypography>
                                                <MKButton
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate(`/details-news/${item?.id}`, { state: { data: item } })
                                                    }}
                                                    rel="noreferrer"
                                                    variant="contained"
                                                    size="small"
                                                    color={"primary"}>
                                                    {t("Presentation.bouton")}
                                                </MKButton>
                                            </Box>
                                            <Box
                                                sx={{
                                                    top: 0,
                                                    width: "100%",
                                                    bottom: 0,
                                                    left: 0, // Positionner la ligne à gauche
                                                    borderTop: '2px solid grey', // Définit la ligne verticale
                                                }}
                                            />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default DetailNews;

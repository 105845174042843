/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Box, Grid, Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CenteredBlogCard({ image, title, description, action, date, data }) {
  const navigate = useNavigate();

  console.log(description)

  return (
    <Grid item xs={12} md={4}>
      <Card
        sx={{
          boxShadow: "0 4px 10px 0 rgba(8, 56, 4, 0.6)",
          display: "inline-block",
          width: "100%",  // Ajustement de la largeur pour occuper toute la largeur sur les petits écrans
          margin: 'auto',
          mb: 2,  // Marge en bas entre les cartes
        }}
      >
        <MKBox borderRadius="md" mx={2} style={{ margin: 20, display: "flex", justifyContent: "center" }}>
          <MKBox
            component="img"
            src={image}
            alt={title}
            borderRadius="md"
            maxWidth="100%"
            width="90%"
            height="160px"
          />
        </MKBox>
        <MKBox p={6} mt={-6} textAlign="left">
          <MKTypography style={{
            color: "#083804",
            fontWeight: "bold",
            textTransform: "capitalize",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            lineClamp: 2,
          }} display="inline" variant="h5" mb={2}>
            {title}
          </MKTypography>
          {/* <MKBox mt={1} mb={3}>
            <MKTypography variant="body2" component="p" color="text">
              {description}
            </MKTypography>
          </MKBox> */}
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",  // Align vertically in the center
          }}>
            <MKBox mt={1} mb={3} sx={{ flexGrow: 1 }}>
              <MKTypography variant="caption" component="p" color="text">
                <Icon>event</Icon>&nbsp;
                {date}
              </MKTypography>
            </MKBox>
            {action.type === "external" ? (
              <MKButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate(action?.route, { state: { data } })
                }}
                rel="noreferrer"
                variant="gradient"
                size="small"
                color={action.color ? action.color : "dark"}>
                {action.label}&nbsp;
                <Icon>add</Icon>
              </MKButton>
            ) : (
              <MKButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate(action?.route, { state: { data } })
                }}
                rel="noreferrer"
                variant="contained"
                size="small"
                color={"primary"}>
                {action.label}&nbsp;
                <Icon>add_circle</Icon>
              </MKButton>
            )}
          </Box>
        </MKBox>
      </Card>
    </Grid>
  );
}

// Typechecking props for the CenteredBlogCard
CenteredBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  data: PropTypes.any,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default CenteredBlogCard;

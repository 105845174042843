
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";


// Material Kit 2 React components
// import MKBox from "components/MKBox";
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import { useTranslation } from "react-i18next";

function DefaultFooter() {
  const { t } = useTranslation();

  return (
    <Grid bgcolor={"#095228"} component="footer">
      <Grid alignContent={"center"} textAlign={"center"}>
        <MKTypography variant="body2" color={"white"} mt={4}>
          {t("Footer.title")}
        </MKTypography>
        <MKTypography variant="body2" color={"white"} mb={1}>
        {t("Footer.name")}
        </MKTypography>
        <MKTypography variant="body2" color={"white"} mb={4}>
          {"©"} {new Date().getFullYear()} FNTEC
        </MKTypography>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;

import { Card, CardContent, Container, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import footerRoutes from "footer.routes";
import routes from "routes";
// Images
import bgImage from "assets/images/front2.jpeg";
import { useTranslation } from "react-i18next";
import minefop from "assets/images/minefop.jpg";
import { useEffect, useState } from "react";
import MKButton from "components/MKButton";
import { Link, useNavigate } from "react-router-dom";
import usePostWithFile from "hooks/usePostWithFile";
import useDataFetching from "hooks/useDataFetching";
import { config } from "constants/config/config";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Register() {
    const { t } = useTranslation();
    const [email] = useState("");
    const [departement, setDepartement] = useState("");
    const [region, setRegion] = useState("");
    const [errorData, setErrorData] = useState("");
    const [errorFile, setErrorFile] = useState("");
    const [attestation, setAttestation] = useState();
    const [errors, setErrors] = useState({});
    const [postDataWithFile, loading, error, data] = usePostWithFile(`${config.app.api_url}/auth/register-enterprise-account`);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const [expanded, setExpanded] = useState('panel1');

    const handleChangePanel =
        (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };

    const [formData, setFormData] = useState({
        namerh: '',
        mailrh: '',
        phonerh: '',
        secteur: '',
        region: '',
        niu: '',
        numberEmployer: '',
        postal: '',
        street: '',
        localite: '',
        departement: '',
        arrondissement: '',
        website: '',
        whatsappphonerh: '',
        password: '',
        email: '',
        sigle: '',
        phone: '',
        name: '',
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // FETCH REGIONS LIST
    const [loadingS, errorS, secteurs, fetchSecteurs] = useDataFetching(
        `${config.app.api_url}/activity-sectors`
    );

    // FETCH REGIONS LIST
    const [loadingR, errorR, regions, fetchRegions] = useDataFetching(
        `${config.app.api_url}/regions`
    );
    // FETCH departments by region id
    const [loadingD, errorD, departements] = useDataFetching(
        `${config.app.api_url}/regions/${region !== "*" && region}/departments`
    );

    // FETCH arrondisements by department id
    const [loadingA, errorA, arrondisements] = useDataFetching(
        `${config.app.api_url}/departments/${departement}/arrondissements`
    );

    useEffect(() => {
        fetchRegions();
        fetchSecteurs();
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "region") {
            setRegion(value)
        }
        if (name === "departement") {
            setDepartement(value)
        }

        // Met à jour les valeurs des champs
        setFormData({
            ...formData,
            [name]: value,
        });

        // Retire l'erreur si le champ est rempli
        if (value) {
            setErrors({
                ...errors,
                [name]: '',
            });
        }
    };

    console.log("Eror ", errorR, errorS, errorD, errorA, loadingA, loadingD)

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 10 * 1024 * 1024; // 10 Mo
        const allowedTypes = ['application/pdf'];

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFile(t("Register.errors.pdf"))
            } else if (file.size > maxSize) {
                e.target.value = null; // Réinitialiser le champ de fichier
                setErrorFile(t("Register.errors.file"))
            } else {
                setErrors({
                    ...errors,
                    "attestation": '',
                });
                setErrorFile("")
                console.log("File ", file)
                setAttestation(file); // Si tout est correct, enregistrer le fichier
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        if (!formData?.name) newErrors.name = t("Register.errors.name");
        if (!formData?.phone) newErrors.phone = t("Register.errors.phone");
        if (!formData?.email) newErrors.email = t("Register.errors.email");
        if (!formData?.password) newErrors.password = t("Register.errors.password");
        if (!formData?.niu) newErrors.niu = t("Register.errors.niu");
        if (!formData?.attestation) newErrors.attestation = t("Register.errors.attestation");
        if (!formData?.region) newErrors.region = t("Register.errors.region");
        if (!formData?.departement) newErrors.departement = t("Register.errors.departement");
        if (!formData?.arrondissement) newErrors.arrondissement = t("Register.errors.arrondissement");
        if (!formData?.numberEmployer) newErrors.numberEmployer = t("Register.errors.numberEmployer");
        if (!formData?.secteur) newErrors.secteur = t("Register.errors.secteur");
        if (!formData?.localite) newErrors.localite = t("Register.errors.localite");
        if (!formData?.namerh) newErrors.namerh = t("Register.errors.namerh");
        if (!formData?.mailrh) newErrors.mailrh = t("Register.errors.mailrh");
        if (!formData?.phonerh) newErrors.phonerh = t("Register.errors.phonerh");
        // console.log("Attest ", newErrors)
        if (!attestation) newErrors.attestation = errorFile;

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            const formD = new FormData();
            const enterprise = {
                socialRaison: formData?.name,
                sigle: formData?.sigle,
                locality: formData?.localite,
                postal: formData?.postal,
                nui: formData?.niu,
                regionId: formData?.region,
                arrondissementId: formData?.arrondissement,
                departmentId: formData?.departement,
                activitySectors: formData?.secteur,
                email: formData?.email,
                phone: formData?.phone,
                website: formData?.website,
                employersNumber: formData?.numberEmployer,
                rhname: formData?.namerh,
                rhemail: formData?.mailrh,
                rhphone: formData?.phonerh,
                rhphonewh: formData?.whatsappphonerh,
                street: formData?.street
            }
            formD.append('file', attestation);
            formD.append('email', formData?.email);
            formD.append('password', formData?.password);
            formD.append('enterpriseName', formData?.name);
            formD.append('enterprise', enterprise);

            // Envoi du fichier avec le FormData
            await postDataWithFile(formD);
        }
    };

    useEffect(() => {
        console.log("DATA ", data)
        if (data && (data?.status == 200 || data?.status == 201)) {
            if (data && data?.access_token) {
                navigate("/account/verification", { state: { data, mail: email } })
            }
        } else {
            console.log("DATA ERR ", data)
            setErrorData(data?.message)
        }
    }, [data])

    return (
        <MKBox sx={{ background: "#fff" }}>
            <DefaultNavbar
                routes={routes}
                sticky={true}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "default",
                }}
                transparent
                light
            />
            <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        flexDirection="column"
                        sx={{ textAlign: "left", mt: 10 }}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                        >
                            {t("Register.title")}
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Container>
                <Grid className="d-flex justify-content-center">
                    <img
                        className="d-block w-20"
                        src={minefop}
                        style={{ height: '30vh' }}
                    />
                </Grid>
                <Grid className="d-flex justify-content-center">
                    <Card style={{ background: "rgba(8, 56, 4, 0.72)", width: "150vh" }}>
                        <CardContent style={{ textAlign: "center" }}>
                            <MKTypography
                                variant="h5"
                                color="white"
                            >
                                {t("Register.card.title")}
                            </MKTypography>
                            {error && <MKTypography
                                variant="subtitle2"
                                color="white"
                                style={{ background: "red", mt: 2 }}
                            >
                                {error}
                            </MKTypography>}
                            {errorData && <MKTypography
                                variant="subtitle2"
                                color="white"
                                style={{ background: "red", mt: 2 }}
                            >
                                {errorData}
                            </MKTypography>}
                            {errorFile && <MKTypography
                                variant="subtitle2"
                                color="black"
                                style={{ background: "yellow", mt: 2 }}
                            >
                                {errorFile}
                            </MKTypography>}
                            <Container>
                                <form onSubmit={handleSubmit}>
                                    <MKBox>
                                        <Grid container spacing={2} mt={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    sx={{
                                                        width: "100%",
                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    value={formData?.name}
                                                    // onChange={(e) => {
                                                    //     setName(e.target.value);
                                                    // }}
                                                    id="outlined-required"
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    name="name"
                                                    label={t("Register.card.name")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.name}
                                                    helperText={errors.name}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    sx={{
                                                        width: "100%",
                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    value={formData?.sigle}
                                                    // onChange={(e) => {
                                                    //     setSigle(e.target.value);
                                                    // }}
                                                    id="outlined-required"
                                                    onChange={handleInputChange}
                                                    name="sigle"
                                                    type="text"
                                                    label={t("Register.card.sigle")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.sigle}
                                                    helperText={errors.sigle}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    sx={{
                                                        width: "100%",
                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    value={formData?.niu}
                                                    // onChange={(e) => {
                                                    //     setNiu(e.target.value);
                                                    // }}
                                                    onChange={handleInputChange}
                                                    name="niu"
                                                    placeholder="JNDOISDWEN832948"
                                                    id="outlined-required"
                                                    label={t("Register.card.niu")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.niu}
                                                    helperText={errors.niu}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    sx={{
                                                        width: "100%",
                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    value={formData?.email}
                                                    // onChange={(e) => {
                                                    //     setEmail(e.target.value);
                                                    // }}
                                                    onChange={handleInputChange}
                                                    name="email"
                                                    placeholder="example@example.com"
                                                    id="outlined-required"
                                                    type="email"
                                                    label={t("Register.card.email")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.email}
                                                    helperText={errors.email}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    sx={{
                                                        width: "100%",
                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    value={formData?.phone}
                                                    // onChange={(e) => {
                                                    //     setPhone(e.target.value);
                                                    // }}
                                                    onChange={handleInputChange}
                                                    name="phone"
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Register.card.phone")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.phone}
                                                    helperText={errors.phone}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    sx={{
                                                        width: "100%",
                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    value={formData?.numberEmployer}
                                                    // onChange={(e) => {
                                                    //     setNumberEmployer(e.target.value);
                                                    // }}
                                                    onChange={handleInputChange}
                                                    name="numberEmployer"
                                                    id="outlined-required"
                                                    type="number"
                                                    label={t("Register.card.numberEmployer")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.numberEmployer}
                                                    helperText={errors.numberEmployer}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {!loadingS && <TextField
                                                    id="outlined-required"
                                                    required
                                                    select
                                                    sx={{
                                                        width: "100%",
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    label={t("Register.card.secteur")}
                                                    value={formData?.secteur}
                                                    // onChange={(e) => {
                                                    //     setSecteur(e.target.value)
                                                    // }}
                                                    onChange={handleInputChange}
                                                    name="secteur"
                                                    InputProps={{
                                                        sx: { height: '50px' }, // Ajustez la hauteur ici
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.secteur}
                                                    helperText={errors.secteur}
                                                >
                                                    {secteurs?.data && secteurs?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </TextField>}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    required
                                                    sx={{
                                                        width: "100%",
                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    onChange={handleInputChange}
                                                    name="password"
                                                    value={formData?.password}
                                                    // onChange={(e) => {
                                                    //     setPassword(e.target.value);
                                                    // }}
                                                    placeholder="*********************"
                                                    id="outlined-required"
                                                    type={showPassword ? 'text' : 'password'}
                                                    label={t("Register.card.password")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.password}
                                                    helperText={errors.password}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label={showPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'}
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {/* <MKTypography
                                        variant="subtitle2"
                                        color="white"
                                    >
                                        {t("Register.card.attestation")}
                                    </MKTypography> */}
                                                <TextField
                                                    required
                                                    sx={{
                                                        width: "100%",
                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#fff",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                            color: "#fff", // Couleur du placeholder
                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                        },
                                                    }}
                                                    onChange={handleFileChange}
                                                    type="file"
                                                    id="outlined-required"
                                                    label={t("Register.card.attestation")}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={!!errors.attestation}
                                                    helperText={errors.attestation}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Accordion expanded={expanded === 'panel1'} onChange={handleChangePanel('panel1')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                    >
                                                        {t("Register.adresse")}
                                                    </AccordionSummary>
                                                    <AccordionDetails sx={{ background: "rgba(8, 56, 4, 0.72)" }}>
                                                        <Grid container spacing={2} mt={2}>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#fff",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                                            color: "#fff", // Couleur du placeholder
                                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                                        },
                                                                    }}
                                                                    value={formData?.postal}
                                                                    onChange={handleInputChange}
                                                                    name="postal"
                                                                    // onChange={(e) => {
                                                                    //     setPostal(e.target.value);
                                                                    // }}
                                                                    id="outlined-required"
                                                                    type="text"
                                                                    label={t("Register.card.postal")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#fff",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                                            color: "#fff", // Couleur du placeholder
                                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                                        },
                                                                    }}
                                                                    onChange={handleInputChange}
                                                                    name="street"
                                                                    value={formData?.street}
                                                                    // onChange={(e) => {
                                                                    //     setStreet(e.target.value);
                                                                    // }}
                                                                    id="outlined-required"
                                                                    type="text"
                                                                    label={t("Register.card.street")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    required
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#fff",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                                            color: "#fff", // Couleur du placeholder
                                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                                        },
                                                                    }}
                                                                    onChange={handleInputChange}
                                                                    name="localite"
                                                                    value={formData?.localite}
                                                                    // onChange={(e) => {
                                                                    //     setLocalite(e.target.value);
                                                                    // }}
                                                                    id="outlined-required"
                                                                    type="text"
                                                                    label={t("Register.card.localite")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    error={!!errors.localite}
                                                                    helperText={errors.localite}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                {!loadingR && <TextField
                                                                    id="outlined-required"
                                                                    required
                                                                    select
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#fff",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                                            color: "#fff", // Couleur du placeholder
                                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                                        },
                                                                    }}
                                                                    SelectProps={{
                                                                        native: true,
                                                                    }}
                                                                    label={t("FNTEC.select.2")}
                                                                    value={formData?.region}
                                                                    // onChange={(e) => {
                                                                    //     setRegion(e.target.value)
                                                                    // }}
                                                                    onChange={handleInputChange}
                                                                    name="region"
                                                                    InputProps={{
                                                                        sx: { height: '50px' }, // Ajustez la hauteur ici
                                                                    }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    error={!!errors.region}
                                                                    helperText={errors.region}
                                                                >
                                                                    {regions?.data && regions?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </TextField>}
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    id="outlined-required"
                                                                    required
                                                                    select
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#fff",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                                            color: "#fff", // Couleur du placeholder
                                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                                        },
                                                                    }}
                                                                    SelectProps={{
                                                                        native: true,
                                                                    }}
                                                                    label={t("FNTEC.select.allDepartment")}
                                                                    value={formData?.departement}
                                                                    // onChange={(e) => {
                                                                    //     if (e.target.value == "*") {
                                                                    //         setArrondissement("*");
                                                                    //     }
                                                                    //     setDepartement(e.target.value)
                                                                    // }}
                                                                    onChange={handleInputChange}
                                                                    name="departement"
                                                                    InputProps={{
                                                                        sx: { height: '50px' }, // Ajustez la hauteur ici
                                                                    }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    error={!!errors.departement}
                                                                    helperText={errors.departement}
                                                                >
                                                                    {departements?.data && departements?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    id="outlined-required"
                                                                    required
                                                                    select
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#fff",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                                            color: "#fff", // Couleur du placeholder
                                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                                        },
                                                                    }}
                                                                    SelectProps={{
                                                                        native: true,
                                                                    }}
                                                                    label={t("FNTEC.select.allDistrict")}
                                                                    value={formData?.arrondissement}
                                                                    // onChange={(e) => {
                                                                    //     setArrondissement(e.target.value)
                                                                    // }}
                                                                    onChange={handleInputChange}
                                                                    name="arrondissement"
                                                                    InputProps={{
                                                                        sx: { height: '50px' }, // Ajustez la hauteur ici
                                                                    }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    error={!!errors.departement}
                                                                    helperText={errors.departement}
                                                                >
                                                                    {arrondisements?.data && arrondisements?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#fff",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                                            color: "#fff", // Couleur du placeholder
                                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                                        },
                                                                    }}
                                                                    value={formData?.website}
                                                                    // onChange={(e) => {
                                                                    //     setWebsite(e.target.value);
                                                                    // }}
                                                                    onChange={handleInputChange}
                                                                    name="website"
                                                                    id="outlined-required"
                                                                    type="text"
                                                                    label={t("Register.card.website")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Accordion expanded={expanded === 'panel2'} onChange={handleChangePanel('panel2')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel2-content"
                                                        id="panel2-header"
                                                    >
                                                        {t("Register.drh")}
                                                    </AccordionSummary>
                                                    <AccordionDetails sx={{ background: "rgba(8, 56, 4, 0.72)" }}>
                                                        <Grid container spacing={2} mt={2}>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    required
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#fff",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                                            color: "#fff", // Couleur du placeholder
                                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                                        },
                                                                    }}
                                                                    value={formData?.namerh}
                                                                    // onChange={(e) => {
                                                                    //     setNamerh(e.target.value);
                                                                    // }}
                                                                    onChange={handleInputChange}
                                                                    name="namerh"
                                                                    id="outlined-required"
                                                                    type="text"
                                                                    label={t("Register.card.namerh")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    error={!!errors.namerh}
                                                                    helperText={errors.namerh}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    required
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#fff",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                                            color: "#fff", // Couleur du placeholder
                                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                                        },
                                                                    }}
                                                                    onChange={handleInputChange}
                                                                    name="mailrh"
                                                                    value={formData?.mailrh}
                                                                    // onChange={(e) => {
                                                                    //     setMailrh(e.target.value);
                                                                    // }}
                                                                    id="outlined-required"
                                                                    type="text"
                                                                    label={t("Register.card.mailrh")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    error={!!errors.mailrh}
                                                                    helperText={errors.mailrh}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    required
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#fff",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                                            color: "#fff", // Couleur du placeholder
                                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                                        },
                                                                    }}
                                                                    value={formData?.phonerh}
                                                                    onChange={handleInputChange}
                                                                    name="phonerh"
                                                                    // onChange={(e) => {
                                                                    //     setPhonerh(e.target.value);
                                                                    // }}
                                                                    id="outlined-required"
                                                                    type="number"
                                                                    label={t("Register.card.phonerh")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    error={!!errors.phonerh}
                                                                    helperText={errors.phonerh}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    sx={{
                                                                        width: "100%",
                                                                        "& .MuiInputBase-input": { color: "#fff" }, // Couleur du texte
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#fff",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#fff", // Couleur de la bordure en mode focus
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": { color: "#fff" }, // Couleur du label
                                                                        "& .MuiInputLabel-root.Mui-focused": { color: "#fff" }, // Couleur du label en focus
                                                                        "& .MuiOutlinedInput-input::placeholder": {
                                                                            color: "#fff", // Couleur du placeholder
                                                                            opacity: 1, // Pour que l'opacité du placeholder soit contrôlée
                                                                        },
                                                                    }}
                                                                    value={formData?.whatsappphonerh}
                                                                    // onChange={(e) => {
                                                                    //     setWhatsappPhonerh(e.target.value);
                                                                    // }}
                                                                    onChange={handleInputChange}
                                                                    name="whatsappphonerh"
                                                                    id="outlined-required"
                                                                    type="number"
                                                                    label={t("Register.card.whatsappphonerh")}
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </Grid>
                                        <MKButton
                                            disabled={loading}
                                            variant="contained"
                                            color="white"
                                            type="submit"
                                            size="large"
                                            component="a"
                                            onClick={handleSubmit}
                                            sx={{ mt: 5, alignSelf: 'center', height: 'fit-content' }}
                                        >
                                            {loading ? t("Register.card.loading") : t("Register.card.create")}
                                        </MKButton>
                                        <Link style={{ textDecoration: "none" }} to="/login">
                                            <MKTypography
                                                mt={3}
                                                variant="subtitle2"
                                                color="white"
                                            >
                                                {t("Register.card.already")} {t("Navbar.Login")}
                                            </MKTypography>
                                        </Link>
                                    </MKBox>
                                </form>
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </MKBox>
    )
}

export default Register;
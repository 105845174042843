/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";
// import Fntec from "pages/LandingPages/FNTEC";
// import Presentation from "pages/Presentation";

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";


const routes = [
  {
    name: "Accueil",
    icon: <Icon>home</Icon>,
    href: "/",
    // component: <Presentation/>
  },
  {
    name: "MINEFOP",
    icon: <Icon>dashboard</Icon>,
    collapse: [
      {
        name: "Présentation du FNTEC",
        description: "Tout savoir sur le Fichier National des Travailleurs de Nationalité Etrangères au Cameroun",
        href: "/",
      },
      {
        name: "Lois et Règlements",
        description: "Connaître les lois et règlements autour du Fichier National des Travailleurs de Nationalité Etrangères au Cameroun",
        href: "/",
      },
      {
        name: "Actualités",
        description: "Explorer l'actualité autour du Fichier National des Travailleurs de Natioanlité Etrangères au Cameroun",
        href: "/",
      },
    ],
  },
  {
    name: "Services",
    icon: <Icon>apps</Icon>,
    collapse: [
      {
        name: "Fichier National des Travailleurs de Nationalité Etrangères",
        description: "Consulter le Fichier National des Travailleurs de Nationalité étrangère au Cameroun",
        route: "/fichier-national-travailleurs-etrangers-cameroun",
        // component:<Fntec/>
      },
      {
        name: "Statistiques",
        description: "Consulter les statistiques des travailleurs de Nationalité étrangère par région ou domaine d'activité",
        href: "/",
      },
    ],
  },
  {
    name: "English",
    icon: <Icon>flag</Icon>,
    collapse: [
      {
        name: "English",
        // description: "Consulter le Fichier National des Travailleurs de Nationalité étrangère au Cameroun",
        // route: "/fichier-national-travailleurs-etrangers-cameroun",
        lang: "en"
      },
      {
        name: "French",
        // description: "Consulter les statistiques des travailleurs de Nationalité étrangère par région ou domaine d'activité",
        lang: "fr",
      },
    ],
  },
  // {
  //   name: "github",
  //   icon: <GitHubIcon />,
  //   href: "https://www.github.com/creativetimofficial/material-kit-react",
  // },
];

export default routes;

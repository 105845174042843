/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/front2.jpeg";
import { Box, CircularProgress, Divider, Icon, Modal, Slide, TableContainer, TablePagination, TextField } from "@mui/material";
import MKButton from "components/MKButton";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { config } from "constants/config/config";
import useDataFetching from "hooks/useDataFetching";
import axios from "axios";
// import MKAlert from "components/MKAlert";
import dayjs from "dayjs";
import './custom.css';
import { useTranslation } from "react-i18next";
import { downloadExcel } from "react-export-table-to-excel";


function Fntec() {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [type, setType] = useState("enterprises");
  const [region, setRegion] = useState("*");
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingE, setLoadingE] = useState(false);
  const [enterprises, setEnterprises] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(null);

  let params = {
    type: 'enterprises',
    fromArchive: 'false',
    withEmpOnly: true
  };

  // FETCH REGIONS LIST
  const [loadingR, errorR, regions, fetchRegions] = useDataFetching(
    `${config.app.api_url}/regions`
  );
  const [departement, setDepartement] = useState(null);
  const [arrondissement, setArrondissement] = useState(null);

  // FETCH departments by region id
  const [loadingD, errorD, departements] = useDataFetching(
    `${config.app.api_url}/regions/${region !== "*" && region}/departments`
  );

  // FETCH arrondisements by department id
  const [loadingA, errorA, arrondisements] = useDataFetching(
    `${config.app.api_url}/departments/${departement}/arrondissements`
  );

  console.log("Error R  D A", errorR, errorD, errorA, loadingD, loadingA)

  useEffect(() => {
    fetchRegions();
  }, []);


  useEffect(() => {
    setEnterprises([]);
  }, [type]);

  //FETCH ENTERPRISES DATA
  const fetchDatas = () => {

    // Vérification et ajout des paramètres
    if (type != "") {
      params.type = type;
    }
    if (region == "*") {
      params.departmentId = null;
      params.arrondissementId = null;
    }
    if (region != "*") {
      params.regionId = region;
    }
    if (departement != "*") {
      params.departmentId = departement;
    }
    if (arrondissement != "*") {
      params.arrondissementId = arrondissement;
    }
    if (search != "") {
      params.searchTerm = encodeURIComponent(search);
    }
    params.limit = limit;
    params.page = page;

    setLoading(true);
    axios
      .get(`${config.app.api_url}/${type}/query-names-only`, { params },
        {
          headers: {
            // Authorization: authHeader(),
          }
        }
      )
      .then((res) => {
        setEnterprises(res?.data?.data);
        console.log("Enterprises DATA ", res?.data?.data);
      })
      .catch((err) => {
        console.log("Enterprises error ", err);
        setEnterprises([]);
      })
      .finally(() => setLoading(false));
  };

  const handlePageChange = (event, value) => {
    // event.preventDefault();
    console.log("PAGE ", value)
    setPage(value);
  };

  useEffect(() => {
    fetchDatas();
  }, [page, limit]);

  //FORMATAGE DES SALAIRES
  const formatNumber = (nombre) => {

    if (nombre != null && nombre != undefined) {
      return nombre.toLocaleString('us')
    }
    else {
      return '--'
    }
  }

  // function checkIsValidPermit(employe) {
  //   const contract = getLastContract(employe?.contracts);
  //   // console.log("🚀 ~ checkIsValidPermit ~ contract:", contract)
  //   // console.log("🚀 ~ checkIsValidPermit ~ permitDate:", permitDate)
  //   return contract?.permitNumber != null && contract?.permitNumber != undefined && contract?.permitNumber?.length > 0 && new Date() < getExpiredPermitDate(contract?.permitDate)
  // }

  function getLastContractPermitDate(employe) {
    const contract = getLastContract(employe?.contracts);

    if (contract != null && contract?.permitDate != null && contract?.permitDate != undefined) {
      // console.log("EXP ", getExpiredPermitDate(contract?.permitDate))
      return dayjs(getExpiredPermitDate(contract?.permitDate)).format("DD-MM-YYYY")
    }

    return t("FNTEC.table.employes.contract.notfound");
  }

  function getLastContractPermitDateObt(employe) {
    const contract = getLastContract(employe?.contracts);

    if (contract != null && contract?.permitDate != null && contract?.permitDate != undefined) {
      // console.log("EXP ", getExpiredPermitDate(contract?.permitDate))
      return dayjs(contract?.permitDate).format("DD-MM-YYYY")
    }

    return t("FNTEC.table.employes.contract.notfound");
  }

  function getLastContract(contracts) {
    if (!contracts || contracts?.length < 1) {
      return null
    }

    if (contracts?.length == 1) {
      return contracts[0];
    }

    return contracts?.sort((a, b) => new Date(b?.permitDate) - new Date(a?.permitDate))[0]

  }

  function getExpiredPermitDate(permitDate) {
    return permitDate ? new Date(new Date(permitDate).setFullYear(new Date(permitDate).getFullYear() + 2)) : null
  }

  function checkIsValidContractDate(permitDate) {
    return new Date() < getExpiredPermitDate(permitDate)
  }

  function checkContractDate(employe) {
    const contract = getLastContract(employe?.contracts);

    if (contract != null && contract?.permitDate != null && contract?.permitDate != undefined) {
      return new Date() < getExpiredPermitDate(contract?.permitDate)
    }

    return false;
  }

  const [show, setShow] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);

  function createMapLink(address) {
    // Encode any special characters in the address
    const encodedAddress = encodeURIComponent(address);
    // Build the URL with the address parameter
    const url = `https://maps.google.com/maps?q=${encodedAddress}`;
    return url;
  }

  useEffect(() => {
    fetchDatas();
  }, []);


  //FETCH EMPLOYEES BY ENTERPRISE ID
  const showEmployees = (item) => {
    if (!item) return
    setLoadingE(true);
    setSelectedEnterprise(item);
    setEmployees([]);
    axios
      .get(`${config.app.api_url}/enterprises/${item?.id}/employers?valid=true`,
        {
          headers: {
            // Authorization: authHeader(),
          }
        }
      )
      .then((res) => {
        setEmployees(res?.data?.data);
      })
      .catch((err) => {
        console.log("EMPLOYEES ERR ", err);
        setEmployees([]);
      })
      .finally(() => {
        setLoadingE(false)
        setShow(true)
      });
  };

  const handleRowClick = (item, index) => {
    setLoadingIndex(index);
    showEmployees(item);
  };

  function handleDownload(event) {
    event.preventDefault();
    let headerTable = [];
    let filename = "";
    let body = {};
    if (type === "enterprises") {
      headerTable = [
        "#",
        t("FNTEC.table.enterprise.raison"),
        t("FNTEC.table.enterprise.sigle"),
        t("FNTEC.table.enterprise.niu"),
        t("FNTEC.table.enterprise.expatries"),
        t("FNTEC.table.enterprise.sector"),
        t("FNTEC.table.enterprise.region"),
        t("FNTEC.table.enterprise.department"),
        t("FNTEC.table.enterprise.arrondissement"),
        t("FNTEC.table.enterprise.ville"),
        t("FNTEC.table.enterprise.locality"),
        t("FNTEC.table.enterprise.itineraire"),
      ];
      filename = `enterprises_${dayjs(new Date())?.format("DD/MM/YYYY")}`;
      body = enterprises?.content && enterprises?.content?.map((item, index) => {
        return [
          index,
          item?.socialRaison?.toUpperCase(),
          item?.sigle?.toUpperCase(),
          item?.nui?.toUpperCase(),
          (item?.employersNumber || item?.employersRealNumber || '--'),
          item?.activitySectors
            ?.map((ac) => {
              return ac?.name?.toUpperCase();
            })
            ?.join(", "),
          item?.region?.name?.toUpperCase(),
          item?.department?.name?.toUpperCase(),
          item?.arrondissement?.name?.toUpperCase(),
          item?.locality?.toUpperCase(),
          item?.street?.toUpperCase(),
          item?.longitude != null ? item?.longitude + "," + item?.latitude : t("notfound")
        ];
      })
    } else {
      headerTable = [
        "#",
        "Noms Complet",
        "Sexe",
        "Poste",
        "Nationalite",
        "Date d'expiration du permis",
        "Status du Permis",
      ];
      filename = `expatries_${dayjs(new Date())?.format("DD/MM/YYYY")}`;

      body = enterprises?.content && enterprises?.content?.map((e, index) => {
        return [
          index,
          e?.firstName + " " + e?.lastName,
          e?.sexe,
          e?.occupation?.name,
          e?.nationality,
          e?.permitDate ? dayjs(getExpiredPermitDate(e?.permitDate)).format("DD-MM-YYYY") : t("FNTEC.table.employes.contract.notfound"),
          !checkIsValidContractDate(e?.permitDate) ? "Expiré" : "Valide",
        ];
      })
    }
    downloadExcel({
      fileName: filename,
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header: headerTable,
        body: body
      },
    });
  }


  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky={true}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="45vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            mt={10}
            flexDirection="column"
            sx={{ textAlign: "left" }}
          >
            <MKTypography
              variant="h2"
              color="white"
            >
              {t("FNTEC.title")}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Grid
        sx={{
          p: 2,
          mb: 4,
        }}
      >
        <Container>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '22ch' },
            }}
            noValidate
            alignSelf={"left"}
            autoComplete="off"
          >
            <MKTypography variant="h3" color="black" sx={{
              position: 'relative',
              display: 'inline-block',
              '::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                width: '50%',
                height: '3px',
                backgroundColor: '#FCE410',
                transform: 'translateX(-200%)',
              },
            }} mb={3} mr={5}>
              {t("FNTEC.search")}
            </MKTypography>
            <div>
              <TextField
                id="outlined-select-currency"
                select
                SelectProps={{
                  native: true,
                }}
                onChange={(e) => {
                  setType(e.target.value)
                }}
                label={t("FNTEC.select.1")}
                helperText=""
                value={type}
                InputProps={{
                  sx: { height: '50px' }, // Ajustez la hauteur ici
                }}
              >
                <option key={"enterprises"} value={"enterprises"}>
                  {t("FNTEC.select.enterprise")}
                </option>
                <option key={"employers"} value={"employers"}>
                  {t("FNTEC.select.employe")}
                </option>
              </TextField>
              {!loadingR && <TextField
                id="outlined-select-currency"
                select
                SelectProps={{
                  native: true,
                }}
                label={t("FNTEC.select.2")}
                value={region}
                onChange={(e) => {
                  if (e.target.value == "*") {
                    setArrondissement("*");
                    setDepartement("*");
                  }
                  setRegion(e.target.value)
                }}
                InputProps={{
                  sx: { height: '50px' }, // Ajustez la hauteur ici
                }}
              >

                <option selected={region == "*" ? true : false} key={"*"} value={"*"}>
                  {t("national")}
                </option>
                {regions?.data && regions?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>}
              <TextField
                id="outlined-select-currency"
                select
                SelectProps={{
                  native: true,
                }}
                value={departement}
                // label={t("FNTEC.select.3")}
                onChange={(e) => {
                  if (e.target.value == "*") {
                    setArrondissement("*");
                  }
                  setDepartement(e.target.value)
                }}
                InputProps={{
                  sx: { height: '50px' }, // Ajustez la hauteur ici
                }}
              >
                <option selected={departement == "*" ? true : false} key={"*"} value={"*"}>
                  {t("FNTEC.select.allDepartment")}
                </option>
                {departements?.data && departements?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
              <TextField
                id="outlined-select-currency"
                select
                // label={t("FNTEC.select.4")}
                SelectProps={{
                  native: true,
                }}
                value={arrondissement}
                onChange={(e) => {
                  setArrondissement(e.target.value)
                }}
                InputProps={{
                  sx: { height: '50px' }, // Ajustez la hauteur ici
                }}
              >
                <option selected={arrondissement == "*" ? true : false} key={"*"} value={"*"}>
                  {t("FNTEC.select.allDistrict")}
                </option>
                {arrondisements?.data && arrondisements?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </div>
          </Box>
          <Box
            component="form"
            sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              '& .MuiTextField-root': { m: 1, flex: 1 },
            }}
            noValidate
            autoComplete="off">
            <TextField
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              id="outlined-required"
              label={t("FNTEC.select.5")}
            />

            <MKButton
              disabled={loading}
              variant="contained"
              color="primary"
              size="large"
              component="a"
              onClick={(e) => {
                e.preventDefault();
                setPage(0);
                fetchDatas();
              }}
              sx={{ mb: 2, alignSelf: 'center', m: 1, height: 'fit-content' }}
            >
              {loading ? t("FNTEC.select.loading") : t("FNTEC.select.bouton")}
            </MKButton>
          </Box>
        </Container>

        <Container sx={{ position: "relative" }}>
          <Grid container item xs={7} lg={12} md={7} mt={2} justifyContent="left" textAlign="left">
            <MKTypography variant="h3" color="black" sx={{
              position: 'relative',
              display: 'inline-block',
              '::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                width: '50%',
                height: '3px',
                backgroundColor: '#FCE410',
                transform: 'translateX(-200%)',
              },
            }} mb={3} mr={5}>
              {t("FNTEC.result")}
            </MKTypography>
            {type == "enterprises" && enterprises?.content ? <MKButton
              variant="contained"
              color="warning"
              size="large"
              component="a"
              sx={{ mb: 2 }}
            >
              {!loading && enterprises?.content ? formatNumber(enterprises?.totalElements) + t("FNTEC.enterprises") : ""}
            </MKButton> : <></>}
            &nbsp;
            {type === "employers" && enterprises?.content ? <MKButton
              variant="contained"
              color="warning"
              size="large"
              component="a"
              sx={{ mb: 2 }}
            >
              {!loading && enterprises?.content ? formatNumber(enterprises?.totalElements) + t("FNTEC.employes") : ""}
            </MKButton> : <></>}
            &nbsp;
            {enterprises?.content && <MKButton
              onClick={handleDownload}
              variant="contained"
              color="primary"
              size="large"
              component="a"
              sx={{ mb: 2 }}
            >
              {t("exportexcel")}
            </MKButton>}
          </Grid>
        </Container>
        <TableContainer>
          <Container>
            <Grid container item justifyContent="center" alignItems={"center"} lg={12}>
              {enterprises?.content && <TablePagination
                component="div"
                count={enterprises?.totalElements}
                page={page}
                onRowsPerPageChange={(event) => {
                  // event.preventDefault();
                  const value = parseInt(event.target.value, 10);
                  console.log("VALUE ", value);
                  setLimit(value);
                  setPage(0);
                }}
                onPageChange={handlePageChange}
                rowsPerPage={limit}
                rowsPerPageOptions={[10, 25, 100]}
                labelRowsPerPage={t("numberPage")}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                lang={i18n.language}
                showFirstButton={true}
                showLastButton={true}
                sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
              />}
            </Grid>
          </Container>
          {type === "enterprises" &&
            <>
              <Table responsive bordered hover size="sm" className="custom-table">
                <thead>
                  <tr style={{ fontSize: "14px" }}>
                    <th className="text-center" style={{ color: "#0F056B" }}>#</th>
                    <th className="text-center" style={{
                      color: "#0F056B",
                      textTransform: "uppercase"
                    }}>{t("FNTEC.table.enterprise.raison")}</th>
                    {/* <th>{t("FNTEC.table.enterprise.sigle")}</th> */}
                    <th className="text-center" style={{
                      color: "#0F056B",
                      textTransform: "uppercase"
                    }}>{t("FNTEC.table.enterprise.niu")}</th>
                    <th className="text-center" style={{
                      color: "#0F056B",
                      textTransform: "uppercase"
                    }}>{t("FNTEC.table.enterprise.expatries")}</th>
                    <th className="text-center" style={{
                      color: "#0F056B",
                      textTransform: "uppercase"
                    }}>{t("FNTEC.table.enterprise.sector")}</th>
                    <th className="text-center" style={{
                      color: "#0F056B",
                      textTransform: "uppercase"
                    }}>{t("FNTEC.table.enterprise.region")}</th>
                    <th className="text-center" style={{
                      color: "#0F056B",
                      textTransform: "uppercase"
                    }}>{t("FNTEC.table.enterprise.department")}</th>
                    <th className="text-center" style={{
                      color: "#0F056B",
                      textTransform: "uppercase"
                    }}>{t("FNTEC.table.enterprise.arrondissement")}</th>
                    <th className="text-center" style={{
                      color: "#0F056B",
                      textTransform: "uppercase"
                    }}>{t("FNTEC.table.enterprise.ville")}</th>
                    <th className="text-center" style={{
                      color: "#0F056B",
                      textTransform: "uppercase"
                    }}>{t("FNTEC.table.enterprise.locality")}</th>
                    <th className="text-center" style={{
                      color: "#0F056B",
                      textTransform: "uppercase"
                    }}>{t("FNTEC.table.enterprise.itineraire")}</th>
                  </tr>
                </thead>
                {!loading && <tbody>
                  {enterprises?.content && enterprises?.content.map((item, index) => (
                    <tr style={{
                      color: "#0F056B",
                      verticalAlign: "bottom",
                      fontSize: "12px",
                      cursor: "pointer", // Ajoute le curseur de pointeur
                      "&:hover": {
                        cursor: "pointer", // Change le curseur lors du survol
                        boxShadow: 3, // Ajoute une ombre lors du survol pour un effet visuel
                      },
                    }} key={index} onClick={(e) => {
                      e.preventDefault();
                      handleRowClick(item, index);
                    }}>
                      <td style={{ color: "#0F056B" }}>{loadingE && loadingIndex === index ? <CircularProgress size={24} /> : (page * limit) + (index + 1)}</td>
                      <td style={{ color: "#0F056B" }}>{item?.socialRaison?.toUpperCase()}</td>
                      {/* <td>{item?.sigle?.toUpperCase()}</td> */}
                      <td style={{ color: "#0F056B" }}>{item?.nui?.toUpperCase()}</td>
                      <td style={{ color: "#0F056B" }} className="text-center">{(item?.employeesNumber || '0')}</td>
                      <td style={{ color: "#0F056B" }}>{item?.activity?.toUpperCase()}</td>
                      <td style={{ color: "#0F056B" }}>{item?.region?.toUpperCase()}</td>
                      <td style={{ color: "#0F056B" }}>{item?.department?.toUpperCase()}</td>
                      <td style={{ color: "#0F056B" }}>{item?.arrondissement?.toUpperCase()}</td>
                      <td style={{ color: "#0F056B" }}>{item?.locality?.toUpperCase()}</td>
                      <td style={{ color: "#0F056B" }}>{item?.street?.toUpperCase()}</td>
                      <td className="text-center">
                        <MKButton onClick={(e) => {
                          e.preventDefault();
                          if (item?.latitude && item?.longitude) {
                            window.open(createMapLink(`${item?.latitude},${item?.longitude}`), '_blank', 'noopener,noreferrer');
                          } else { alert("Coordonées incomplètes"); }
                        }} variant="gradient" color="info" iconOnly>
                          <Icon>place</Icon>
                        </MKButton>
                      </td>
                    </tr>
                  ))}
                </tbody>}
              </Table>
              <Modal open={show} onClose={() => setShow(false)} sx={{ display: "grid", placeItems: "center" }}>
                <Slide direction="down" in={show} timeout={500}>
                  <MKBox
                    position="relative"
                    width="80%"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                  >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                      <MKTypography variant="h5">{t("FNTEC.table.enterprise.details")} : {selectedEnterprise?.socialRaison?.toUpperCase()}</MKTypography>
                      <Icon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => setShow(false)}>close</Icon>
                    </MKBox>
                    <Divider sx={{ my: 0 }} />
                    <MKBox component="section" py={{ xs: 3 }} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                      <Grid container md={12} xs={12} spacing={2} >
                        <Grid item lg={4}>
                          <Grid container>
                            <Grid item sx={{ ml: 2 }}>
                              <MKTypography variant="h5">
                                {selectedEnterprise?.socialRaison?.toUpperCase()}
                              </MKTypography>
                              <MKTypography variant="h6" mb={1} mt={2}>
                                <span style={{ color: "black", fontSize: "17px" }}>{t("FNTEC.table.enterprise.niu")}:</span> {selectedEnterprise?.nui}
                              </MKTypography>
                              <MKTypography variant="h6" mb={1}>
                                <span style={{ color: "black", fontSize: "17px" }}>{t("FNTEC.employes")}:</span> {employees && employees.length}
                              </MKTypography>
                              <MKTypography variant="h6" mb={1}>
                                <span style={{ color: "black", fontSize: "17px" }}>{t("FNTEC.table.enterprise.sector")}:</span> {selectedEnterprise?.activity?.toUpperCase()}
                              </MKTypography>
                              <MKTypography variant="h6" mb={1}>
                                <span style={{ color: "black", fontSize: "17px" }}>{t("FNTEC.table.enterprise.region")}:</span> {selectedEnterprise?.region?.toUpperCase()}
                              </MKTypography>
                              <MKTypography variant="h6" mb={1}>
                                <span style={{ color: "black", fontSize: "17px" }}>{t("FNTEC.table.enterprise.department")}:</span> {selectedEnterprise?.department?.toUpperCase()}
                              </MKTypography>
                              <MKTypography variant="h6" mb={1}>
                                <span style={{ color: "black", fontSize: "17px" }}>{t("FNTEC.table.enterprise.arrondissement")}:</span> {selectedEnterprise?.arrondissement?.toUpperCase()}
                              </MKTypography>
                              <MKTypography variant="h6" mb={1}>
                                <span style={{ color: "black", fontSize: "17px" }}>{t("FNTEC.table.enterprise.locality")}:</span> {selectedEnterprise?.locality?.toUpperCase()}
                              </MKTypography>
                              <MKButton onClick={(e) => {
                                e.preventDefault();
                                if (selectedEnterprise?.latitude && selectedEnterprise?.longitude) {
                                  window.open(createMapLink(`${selectedEnterprise.latitude},${selectedEnterprise?.longitude}`), '_blank', 'noopener,noreferrer');
                                } else { alert("Coordonées incomplètes"); }
                              }} variant="gradient" color="info">
                                <Icon>place</Icon> {t("geolocation")}
                              </MKButton>
                            </Grid>
                            {/* <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                            <Stack>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small">qr_code</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  <span style={{ color: "black", fontSize: "20px" }}>{t("FNTEC.table.enterprise.niu")}</span>
                                  <br />
                                  {selectedEnterprise?.nui}
                                </MKTypography>
                              </MKBox>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small">people</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  <span style={{ color: "black", fontSize: "20px" }}>{t("FNTEC.employes")}</span>
                                  <br />
                                  {employees && employees.length}
                                </MKTypography>
                              </MKBox>
                              <MKBox display="flex" alignItems="center" p={2}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                  sx={{
                                    cursor: "pointer", // Ajoute le curseur de pointeur
                                    "&:hover": {
                                      cursor: "pointer", // Change le curseur lors du survol
                                      boxShadow: 3, // Ajoute une ombre lors du survol pour un effet visuel
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (selectedEnterprise?.latitude && selectedEnterprise?.longitude) {
                                      window.open(createMapLink(`${selectedEnterprise?.latitude},${selectedEnterprise?.longitude}`), '_blank', 'noopener,noreferrer');
                                    } else { alert("Coordonées incomplètes"); }
                                  }}
                                >
                                  <Icon fontSize="small">place</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={2}>
                                  <span style={{ color: "black", fontSize: "20px" }}>{t("FNTEC.Localisation")}</span>
                                  <br />
                                  {selectedEnterprise?.longitude != null ? selectedEnterprise?.longitude + "," + selectedEnterprise?.latitude : t("notfound")}
                                </MKTypography>
                              </MKBox>
                            </Stack>
                          </Grid> */}
                          </Grid>
                        </Grid>
                        <Grid item lg={8}>
                          {/* <Grid container justifyContent="right" textAlign="right">
                            <MKTypography variant="h4" color="black">
                              {t("FNTEC.table.employes.list")}
                            </MKTypography>
                          </Grid> */}
                          <MKBox component="section" py={{ xs: 3 }} sx={{ overflow: 'auto' }}>
                            <Table responsive bordered hover size="sm" className="custom-table">
                              <thead>
                                <tr style={{ fontSize: "14px" }}>
                                  <th className="text-center" style={{
                                    color: "#0F056B",
                                    textTransform: "uppercase"
                                  }}>#</th>
                                  <th className="text-center" style={{
                                    color: "#0F056B",
                                    textTransform: "uppercase"
                                  }}>{t("FNTEC.table.employes.noms")}</th>
                                  <th className="text-center" style={{
                                    color: "#0F056B",
                                    textTransform: "uppercase"
                                  }}>{t("FNTEC.table.employes.sexe")}</th>
                                  <th className="text-center" style={{
                                    color: "#0F056B",
                                    textTransform: "uppercase"
                                  }}>{t("FNTEC.table.employes.poste")}</th>
                                  {/* <th>Entreprise</th> */}
                                  {/* <th>Salaire</th> */}
                                  <th className="text-center" style={{
                                    color: "#0F056B",
                                    textTransform: "uppercase"
                                  }}>{t("FNTEC.table.employes.nationality")}</th>
                                  <th className="text-center" style={{
                                    color: "#0F056B",
                                    textTransform: "uppercase"
                                  }}>{t("FNTEC.table.employes.obtention")}</th>
                                  <th className="text-center" style={{
                                    color: "#0F056B",
                                    textTransform: "uppercase"
                                  }}>{t("FNTEC.table.employes.expire")}</th>
                                  <th className="text-center" style={{
                                    color: "#0F056B",
                                    textTransform: "uppercase"
                                  }}>{t("FNTEC.table.employes.statut")}</th>
                                </tr>
                              </thead>
                              {!loadingE && <tbody>
                                {employees && employees.map((item, index) => (
                                  <tr style={{
                                    color: "#0F056B",
                                    verticalAlign: "bottom",
                                    fontSize: "12px"
                                  }} key={index}>
                                    <td style={{ color: "#0F056B" }}>{index + 1}</td>
                                    <td style={{ color: "#0F056B" }}>{(item?.fullName?.toUpperCase() || item?.firstName?.toUpperCase() + " " + item?.lastName?.toUpperCase())}</td>
                                    <td style={{ color: "#0F056B" }}>{item?.sexe?.toUpperCase()}</td>
                                    <td style={{ color: "#0F056B" }}>{item?.occupation?.name?.toUpperCase()}</td>
                                    {/* <td>{item?.enterprise?.socialRaison.toUpperCase()}</td> */}
                                    {/* <td>{formatNumber(item?.salary)}</td> */}
                                    <td style={{ color: "#0F056B" }}>{item?.nationality?.toUpperCase()}</td>
                                    <td style={{ color: "#0F056B" }}>{getLastContractPermitDateObt(item)}</td>
                                    <td style={{ color: "#0F056B" }}>{getLastContractPermitDate(item)}</td>
                                    <td className="text-center">{!checkContractDate(item) ?
                                      <Icon style={{ color: "#FF0000" }} fontSize="inherit">thumb_down</Icon>
                                      :
                                      <Icon style={{ color: "#008000" }} fontSize="inherit">thumb_up</Icon>}</td>
                                  </tr>
                                ))}
                              </tbody>}
                            </Table>
                          </MKBox>
                        </Grid>
                      </Grid>

                    </MKBox>
                    <MKBox display="flex" justifyContent="right" p={1.5}>
                      <MKButton variant="gradient" color="dark" onClick={() => setShow(false)}>
                        {t("FNTEC.table.enterprise.close")}
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </Slide>
              </Modal>
            </>
          }
          {type === "employers" &&
            <Table responsive striped bordered hover size="sm" variant="light" className="custom-table">
              <thead>
                <tr style={{ fontSize: "14px" }}>
                  <th className="text-center" style={{
                    color: "#0F056B",
                    textTransform: "uppercase"
                  }}>#</th>
                  <th className="text-center" style={{
                    color: "#0F056B",
                    textTransform: "uppercase"
                  }}>{t("FNTEC.table.employes.noms")}</th>
                  <th className="text-center" style={{
                    color: "#0F056B",
                    textTransform: "uppercase"
                  }}>{t("FNTEC.table.employes.sexe")}</th>
                  <th className="text-center" style={{
                    color: "#0F056B",
                    textTransform: "uppercase"
                  }}>{t("FNTEC.table.employes.poste")}</th>
                  <th className="text-center" style={{
                    color: "#0F056B",
                    textTransform: "uppercase"
                  }}>{t("FNTEC.enterprises")}</th>
                  {/* <th>Salaire</th> */}
                  <th className="text-center" style={{
                    color: "#0F056B",
                    textTransform: "uppercase"
                  }}>{t("FNTEC.table.employes.nationality")}</th>
                  <th className="text-center" style={{
                    color: "#0F056B",
                    textTransform: "uppercase"
                  }}>{t("FNTEC.table.employes.obtention")}</th>
                  <th className="text-center" style={{
                    color: "#0F056B",
                    textTransform: "uppercase"
                  }}>{t("FNTEC.table.employes.expire")}</th>
                  <th className="text-center" style={{
                    color: "#0F056B",
                    textTransform: "uppercase"
                  }}>{t("FNTEC.table.employes.statut")}</th>
                </tr>
              </thead>
              {!loading && <tbody>
                {enterprises?.content && enterprises?.content.map((item, index) => (
                  <tr style={{
                    color: "#0F056B",
                    verticalAlign: "bottom",
                    fontSize: "12px"
                  }} key={index}>
                    <td style={{ color: "#0F056B" }}>{(page * limit) + (index + 1)}</td>
                    <td style={{ color: "#0F056B" }}>{(item?.fullName?.toUpperCase() || item?.firstName?.toUpperCase() + " " + item?.lastName?.toUpperCase())}</td>
                    <td style={{ color: "#0F056B" }}>{item?.sexe?.toUpperCase()}</td>
                    <td style={{ color: "#0F056B" }}>{item?.occupation?.toUpperCase()}</td>
                    <td style={{ color: "#0F056B" }}>{item?.enterpriseName?.toUpperCase()}</td>
                    {/* <td>{formatNumber(item?.salary)}</td> */}
                    <td style={{ color: "#0F056B" }}>{item?.nationality?.toUpperCase()}</td>
                    <td style={{ color: "#0F056B" }}>{item?.permitDate ? dayjs(item?.permitDate).format("DD-MM-YYYY") : t("FNTEC.table.employes.contract.notfound")}</td>
                    <td style={{ color: "#0F056B" }}>{item?.permitDate ? dayjs(getExpiredPermitDate(item?.permitDate)).format("DD-MM-YYYY") : t("FNTEC.table.employes.contract.notfound")}</td>
                    <td className="text-center">{!checkIsValidContractDate(item?.permitDate) ?
                      <Icon style={{ color: "#FF0000" }} fontSize="inherit">thumb_down</Icon>
                      :
                      <Icon style={{ color: "#008000" }} fontSize="inherit">thumb_up</Icon>
                    }</td>
                  </tr>
                ))}
              </tbody>}
            </Table>
          }
          <Container>
            <Grid container item justifyContent="center" lg={12}>
              {enterprises?.content && <TablePagination
                component="div"
                count={enterprises?.totalElements}
                page={page}
                onRowsPerPageChange={(event) => {
                  const value = parseInt(event.target.value, 10);
                  console.log("VALUE ", value);
                  setLimit(value);
                  setPage(0);
                }}
                onPageChange={handlePageChange}
                rowsPerPage={limit}
                labelRowsPerPage={t("numberPage")}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                lang={i18n.language}
                showFirstButton={true}
                showLastButton={true}
              />}
            </Grid>
          </Container>
        </TableContainer>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Fntec;

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useTranslation } from "react-i18next";

// Images
// import bgImage from "assets/images/shapes/waves-white.svg";

function Download() {
  const { t } = useTranslation();

  return (
    <MKBox component="section" py={{ xs: 0, sm: 8 }}>
      <MKBox
        variant="gradient"
        bgColor="light"
        position="relative"
        sx={{ overflow: "hidden" }}
      >
        <MKBox
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          opacity={0.2}
        />
        <Container sx={{ position: "relative", zIndex: 2, py: 2 }}>
          <Grid container item xs={12} justifyContent="left" textAlign="left">
            <MKTypography variant="h3" color="black" sx={{
              position: 'relative',
              display: 'inline-block',
              '::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                width: '50%',
                height: '3px',
                backgroundColor: '#FCE410',
                transform: 'translateX(-200%)',
              },
            }} mb={3}>
              {t("Presentation.title")}
            </MKTypography>
            {/* <MKTypography variant="h3" color="white" mb={1}>
              UI Kit for ReactJS &amp; MUI?
            </MKTypography> */}
            <MKTypography variant="body2" color="black" mb={6} sx={{ textAlign: 'justify' }}>
            {t("Presentation.description")}
            </MKTypography>
            <MKButton
              variant="contained"
              color="primary"
              size="large"
              component="a"
              href="/about"
              sx={{ mb: 2 }}
            >
              {t("Presentation.bouton")}
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Download;

import { useAuth } from 'AuthProvider';
import i18n from 'i18n';
import { useState } from 'react';

const usePost = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { token } = useAuth();

  const postData = async (body) => {
    setLoading(true);
    setError(null);

    try {

      const headers = {'Content-Type': 'application/json', 'Accept-Language': i18n.language};
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      });

      // if (!response.ok) {
      //   throw new Error('Une erreur est survenue lors de la requête.');
      // }

      const result = await response.json();
      setData(result);
    } catch (err) {
      console.log("err ", err)
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return [ postData, loading, error, data ];
};

export default usePost;

import { Box, Card, CardContent, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useAuth } from 'AuthProvider';
import { config } from 'constants/config/config';
import useDataFetching from 'hooks/useDataFetching';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';


function Stats({ children, value, index, ...other }) {
    const { t } = useTranslation();
    const { user } = useAuth();

    const [loading, error, stats, fetch] = useDataFetching(
        `${config.app.api_url}/enterprises/${user?.myEnterprise?.id}/stats-details`
    );
    console.log(children, error)

    useEffect(() => {
        fetch();
    }, [user])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <ToastContainer />
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {loading && <CircularProgress />}
                    {
                        stats?.data &&
                        <Container>
                            <Grid container spacing={2}>
                                {/* Première ligne */}
                                <Grid item xs={4}>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {stats?.data?.employeesStats?.total}
                                            </Typography>
                                            {/* <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>adjective</Typography> */}
                                            <Typography variant="body2">
                                                {t("Declaration.Stats.employe.total")}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {stats?.data?.employeesStats?.regular}
                                            </Typography>
                                            {/* <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>adjective</Typography> */}
                                            <Typography variant="body2">
                                                {t("Declaration.Stats.employe.regular")}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {stats?.data?.employeesStats?.irregular}
                                            </Typography>
                                            {/* <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>adjective</Typography> */}
                                            <Typography variant="body2">
                                                {t("Declaration.Stats.employe.irregular")}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {stats?.data?.contractsStats?.total}
                                            </Typography>
                                            {/* <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>adjective</Typography> */}
                                            <Typography variant="body2">
                                                {t("Declaration.Stats.contract.total")}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {stats?.data?.contractsStats?.regular}
                                            </Typography>
                                            {/* <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>adjective</Typography> */}
                                            <Typography variant="body2">
                                                {t("Declaration.Stats.contract.regular")}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {stats?.data?.contractsStats?.irregular}
                                            </Typography>
                                            {/* <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>adjective</Typography> */}
                                            <Typography variant="body2">
                                                {t("Declaration.Stats.contract.irregular")}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    }
                </Box>
            )}
        </div>
    )

}

// Ajouter la validation des props
Stats.propTypes = {
    children: PropTypes.string.isRequired, // 'text' doit être une chaîne de caractères
    value: PropTypes.any.isRequired,   // 'value' peut être de n'importe quel type
    index: PropTypes.number.isRequired // 'index' doit être un nombre
};

export default Stats;
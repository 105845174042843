import { AppBar, Box, Container, Grid, Tab, Tabs, useTheme } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import footerRoutes from "footer.routes";
import routes from "routes";
// Images
import bgImage from "assets/images/front2.jpeg";
import { useTranslation } from "react-i18next";
import React from "react";
import Account from "./Account";
import Enterprise from "./Enterprise";
import { useAuth } from "AuthProvider";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function Profile() {
    const { t } = useTranslation();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const { user } = useAuth();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MKBox sx={{ background: "#fff" }}>
            <DefaultNavbar
                routes={routes}
                sticky={true}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "default",
                }}
                transparent
                light
            />
            <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        flexDirection="column"
                        sx={{ textAlign: "left", mt: 10 }}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                        >
                            {t("Profile.title")}
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Container>
                <Grid mt={5} className="d-flex justify-content-center">
                    <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
                        <AppBar position="static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label={t("Profile.Account.title")} {...a11yProps(0)} />
                                {user?.roles[0].name === "ENTERPRISE" && <Tab label={t("Profile.Enterprise.title")} {...a11yProps(1)} />}
                            </Tabs>
                        </AppBar>
                        <Account value={value} index={0} dir={theme.direction}></Account>
                        {user?.roles[0].name === "ENTERPRISE" && <Enterprise value={value} index={1} dir={theme.direction}></Enterprise>}
                    </Box>
                </Grid>
            </Container>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </MKBox>
    )
}

export default Profile;
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/front2.jpeg";
import { useTranslation } from "react-i18next";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import { Box } from "@mui/material";
import newsData from "pages/Presentation/sections/data/newsData";


function News() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <DefaultNavbar
                routes={routes}
                sticky={true}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "default",
                }}
                transparent
                light
            />
            <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        flexDirection="column"
                        sx={{ textAlign: "left" }}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                        >
                            {t("Actualite.title")}
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Grid
                sx={{
                    p: 2,
                    mt: 6,
                    mb: 4,
                }}
            >
                <Container>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap', // Permet aux cartes de passer à la ligne suivante si nécessaire
                        gap: 2, // Espace entre les cartes
                        marginBottom: 4,
                    }}>
                        {
                            newsData.map((item) => (
                                <Box key={item?.id} sx={{
                                    flex: '1 1 30%', // Définit la largeur de chaque carte (environ un tiers de la ligne)
                                    maxWidth: '30%', // S'assure que les cartes ne dépassent pas 30% de la ligne
                                    minWidth: '300px', // Définit une largeur minimale pour chaque carte
                                }}>
                                    <CenteredBlogCard
                                        image={item?.image}
                                        category={{ color: "warning", label: "hub" }}
                                        title={item?.title[i18n.language]}
                                        data={item}
                                        action={{
                                            type: "internal",
                                            route: `/details-news/${item?.id}`,
                                            label: t("Presentation.bouton")
                                        }}
                                        date={item?.date}
                                    />
                                </Box>
                            ))
                        }
                    </Box>
                </Container>
            </Grid>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default News;

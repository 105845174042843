/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Box, Grid, Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { Viewer } from '@react-pdf-viewer/core';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import { useEffect, useRef } from "react";

function LawCards({ title, description, action, date, data }) {
  const navigate = useNavigate();
  const canvasRef = useRef(null);

  console.log(description)


  useEffect(() => {
    let isMounted = true;
    const loadThumbnail = async () => {
      const loadingTask = pdfjsLib.getDocument(data?.file);
      const pdf = await loadingTask.promise;
      if (!isMounted) return;
      const page = await pdf.getPage(1);
      if (!isMounted) return;
      const viewport = page.getViewport({ scale: 1 });
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
    };
    loadThumbnail();

    return () => {
      isMounted = false;
    };
  }, [data])

  return (
    <Grid item xs={12} md={4}>
      <Card
        sx={{
          boxShadow: "0 4px 10px 0 rgba(8, 56, 4, 0.6)",
          display: "inline-block",
          width: "100%",  // Ajustement de la largeur pour occuper toute la largeur sur les petits écrans
          margin: 'auto',
          mb: 2,  // Marge en bas entre les cartes
        }}
      >
        <MKBox sx={{
          boxShadow: "0 2px 2px 0 rgba(8, 56, 4, 0.1)"
        }} borderRadius="md" mx={2} style={{ margin: 20, height: 100, display: "flex", justifyContent: "center" }}>
          <canvas ref={canvasRef} />
        </MKBox>
        <MKBox p={6} mt={-6} textAlign="left">
          <MKTypography style={{
            color: "#083804",
            fontWeight: "bold",
            textTransform: "capitalize",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            lineClamp: 2,
          }} display="inline" variant="h5" mb={2}>
            {title}
          </MKTypography>
          {/* <MKBox mt={1} mb={3}>
            <MKTypography variant="body2" component="p" color="text">
              {description}
            </MKTypography>
          </MKBox> */}
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",  // Align vertically in the center
          }}>
            <MKBox mt={1} mb={3} sx={{ flexGrow: 1 }}>
              <MKTypography variant="caption" component="p" color="text">
                <Icon>event</Icon>&nbsp;
                {date}
              </MKTypography>
            </MKBox>
            {action.type === "external" ? (
              <MKButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate(action?.route, { state: { data } })
                }}
                rel="noreferrer"
                variant="gradient"
                size="small"
                color={action.color ? action.color : "dark"}>
                {action.label}&nbsp;
                <Icon>add</Icon>
              </MKButton>
            ) : (
              <MKButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate(action?.route, { state: { data } })
                }}
                rel="noreferrer"
                variant="contained"
                size="small"
                color={"primary"}>
                {action.label}&nbsp;
                <Icon>add_circle</Icon>
              </MKButton>
            )}
          </Box>
        </MKBox>
      </Card>
    </Grid>
  );
}

// Typechecking props for the CenteredBlogCard
LawCards.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  data: PropTypes.any,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default LawCards;

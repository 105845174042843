/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
// import routes from "routes";
import Fntec from "pages/LandingPages/FNTEC";
import Laws from "pages/Laws";
import News from "pages/News";
import About from "pages/About";
import Details from "pages/Laws/Details";
import DetailNews from "pages/News/Details";
import Statistiques from "pages/Statistiques";
// import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import { Worker } from "@react-pdf-viewer/core";
import Register from "pages/Auth/Register";
import Login from "pages/Auth/Login";
import Declaration from "pages/Declaration";
import Verification from "pages/Auth/Verification";
import Profile from "pages/Auth/Profile";
import PrivateRoute from "PrivateRoute";
// import { GlobalWorkerOptions } from 'pdfjs-dist';
// pdfjsLib.GlobalWorkerOptions.workerSrc = `./worker.js`;

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }

  //     if (route.route) {
  //       return <Route exact path={route.route} element={route.component} key={route.key} />;
  //     }

  //     return null;
  //   });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Worker workerUrl={`./worker.js`}>
        <Routes>
          {/* {getRoutes(routes)} */}
          <Route path="/laws" element={<Laws />} />
          <Route path="/news" element={<News />} />
          <Route path="/about" element={<About />} />
          <Route path="/details-news/:id" element={<DetailNews />} />
          <Route path="/details-laws/:id" element={<Details />} />
          <Route path="/presentation" element={<Presentation />} />
          <Route path="/stats" element={<Statistiques />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/account/verification" element={<Verification />} />
          <Route path="*" element={<Navigate to="/presentation" />} />
          <Route element={<PrivateRoute />}>
            <Route path="/account/profile" element={<Profile />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/fichier-national-travailleurs-etrangers-cameroun" element={<Fntec />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/account/declaration" element={<Declaration />} />
          </Route>
        </Routes>
      </Worker>
    </ThemeProvider>
  );
}

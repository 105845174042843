import { Box, Card, CardContent, Container, Grid, TextField } from "@mui/material";
import { useAuth } from "AuthProvider";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { config } from "constants/config/config";
import useDataFetching from "hooks/useDataFetching";
import usePost from "hooks/usePost";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Enterprise({ children, value, index, ...other }) {
    const { t } = useTranslation();
    const [region, setRegion] = useState("*");
    const [rhname, setRhname] = useState("");
    const [rhphone, setRhphone] = useState("");
    const [employerNumber, setEmployernumber] = useState("");
    const [errors, setErrors] = useState({});

    const { user } = useAuth();

    // FETCH ENTERPRISE LIST
    const [loadingE, errorE, enterprise, fetchEnterprise] = useDataFetching(
        `${config.app.api_url}/enterprises/${user?.myEnterprise?.id}`
    );

    console.log(children, user)
    const [ postData, load, error, data ] = usePost(`${config.app.api_url}/enterprises`);

    // FETCH REGIONS LIST
    const [loadingR, errorR, regions, fetchRegions] = useDataFetching(
        `${config.app.api_url}/regions`
    );
    const [departement, setDepartement] = useState(null);
    const [arrondissement, setArrondissement] = useState(null);

    // FETCH departments by region id
    const [loadingD, errorD, departements] = useDataFetching(
        `${config.app.api_url}/regions/${region !== "*" && region}/departments`
    );

    // FETCH arrondisements by department id
    const [loadingA, errorA, arrondisements] = useDataFetching(
        `${config.app.api_url}/departments/${departement}/arrondissements`
    );

    useEffect(() => {
        fetchEnterprise();
    }, [user])

    useEffect(() => {
        if (enterprise) {
            console.log("ENTERER ", enterprise)
            setEmployernumber(enterprise?.data?.employersNumber);
            setRhname(enterprise?.data?.rhname);
            setRhphone(enterprise?.data?.rhphone);
            setRegion(enterprise?.data?.region?.id);
            setDepartement(enterprise?.data?.department?.id);
            setArrondissement(enterprise?.data?.arrondissement?.id);
        }
    }, [enterprise]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Initialiser les erreurs à vide
        const newErrors = {};

        // Valider les champs
        if (!region) newErrors.region = t("Profile.Enterprise.errors.region");
        if (!departement) newErrors.departement = t("Profile.Enterprise.errors.departement");
        if (!arrondissement) newErrors.arrondissement = t("Profile.Enterprise.errors.arrondissement");
        if (!rhname) newErrors.rhname = t("Profile.Enterprise.errors.rhname");
        if (!rhphone) newErrors.rhphone = t("Profile.Enterprise.errors.rhphone");
        if (!employerNumber) newErrors.employerNumber = t("Profile.Enterprise.errors.employerNumber");

        // Vérifier s'il y a des erreurs
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Si oui, afficher les erreurs
        } else {
            // Logique à exécuter si tous les champs sont remplis
            await postData({ id: user?.myEnterprise?.id, regionId: region, departmentId: departement, arrondisementId: arrondissement, rhname, rhphone, employersNumber: employerNumber });
        }
    };

    console.log("Error R  D A", loadingE, errorE, errorR, errorD, errorA, loadingD, loadingA)

    useEffect(() => {
        fetchRegions();
    }, []);

    useEffect(() => {
        console.log("DATA ", data)
        if (data && data?.data?.id) {
            toast(data?.message || t("success"))
            fetchEnterprise();
        } else {
            toast(data?.message || t("error"))
        }
    }, [data])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Container>
                        <ToastContainer />
                        <Grid className="d-flex justify-content-center">
                            <Card style={{ background: "white", width: "150vh" }}>
                                <CardContent style={{ textAlign: "center" }}>
                                    {error && <MKTypography
                                        variant="subtitle2"
                                        color="white"
                                        style={{ background: "red", mt: 2 }}
                                    >
                                        {error}
                                    </MKTypography>}
                                    <Container>
                                        <Grid className="d-flex justify-content-center">
                                            <form onSubmit={handleSubmit}>
                                                <Grid container spacing={2} mt={2}> {/* container pour gérer l'espacement */}
                                                    {!loadingR &&
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                id="outlined-select-currency"
                                                                select
                                                                SelectProps={{
                                                                    native: true,
                                                                }}
                                                                label={t("FNTEC.select.2")}
                                                                value={region}
                                                                onChange={(e) => {
                                                                    if (e.target.value == "*") {
                                                                        setArrondissement("*");
                                                                        setDepartement("*");
                                                                    }
                                                                    setRegion(e.target.value)
                                                                }}
                                                                sx={{ width: "100%" }}
                                                                InputLabelProps={{ shrink: true }}
                                                                error={!!errors.region}
                                                                helperText={errors.region}
                                                            // placeholder={user?.myEnterprise?.region}
                                                            >
                                                                {regions?.data && regions?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                    <option key={option.id} value={option.id}>
                                                                        {option.name}
                                                                    </option>
                                                                ))}
                                                            </TextField>
                                                        </Grid>}
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            id="outlined-select-currency"
                                                            select
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            value={departement}
                                                            label={t("FNTEC.select.3")}
                                                            onChange={(e) => {
                                                                if (e.target.value == "*") {
                                                                    setArrondissement("*");
                                                                }
                                                                setDepartement(e.target.value)
                                                            }}
                                                            sx={{ width: "100%" }}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.departement}
                                                            helperText={errors.departement}
                                                        // placeholder={user?.myEnterprise?.department}
                                                        >
                                                            {departements?.data && departements?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            id="outlined-select-currency"
                                                            select
                                                            label={t("FNTEC.select.4")}
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            value={arrondissement}
                                                            onChange={(e) => {
                                                                setArrondissement(e.target.value)
                                                            }}
                                                            sx={{ width: "100%" }}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.arrondissement}
                                                            helperText={errors.arrondissement}
                                                        // placeholder={user?.myEnterprise?.arrondissement}
                                                        >
                                                            {arrondisements?.data && arrondisements?.data?.sort((a, b) => a.name.localeCompare(b.name)).map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes sur les écrans moyens et plus */}
                                                        <TextField
                                                            required
                                                            sx={{ width: "100%" }}
                                                            value={rhname}
                                                            onChange={(e) => setRhname(e.target.value)}
                                                            type="text"
                                                            id="outlined-required"
                                                            label={t("Profile.Enterprise.card.rhname")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.rhname}
                                                            helperText={errors.rhname}
                                                        // placeholder={user?.myEnterprise?.rhname}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                        <TextField
                                                            required
                                                            sx={{ width: "100%" }}
                                                            value={rhphone}
                                                            onChange={(e) => setRhphone(e.target.value)}
                                                            id="outlined-required"
                                                            type="number"
                                                            label={t("Profile.Enterprise.card.rhphone")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.rhphone}
                                                            helperText={errors.rhphone}
                                                        // placeholder={user?.myEnterprise?.rhphone}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}> {/* chaque élément prend 6 colonnes */}
                                                        <TextField
                                                            required
                                                            sx={{ width: "100%" }}
                                                            value={employerNumber}
                                                            onChange={(e) => setEmployernumber(e.target.value)}
                                                            id="outlined-required"
                                                            type="number"
                                                            label={t("Profile.Enterprise.card.employerNumber")}
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!!errors.employerNumber}
                                                            helperText={errors.employerNumber}
                                                        // placeholder={user?.myEnterprise?.employersNumber}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <MKButton
                                                    disabled={load}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    component="a"
                                                    onClick={handleSubmit}
                                                    sx={{ mt: 5, alignSelf: 'center', height: 'fit-content' }}
                                                >
                                                    {load ? t("Profile.Enterprise.card.loading") : t("Profile.Enterprise.card.update")}
                                                </MKButton>
                                            </form>
                                        </Grid>
                                    </Container>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Container>
                </Box>
            )}
        </div>
    );
}

// Ajouter la validation des props
Enterprise.propTypes = {
    children: PropTypes.string.isRequired, // 'text' doit être une chaîne de caractères
    value: PropTypes.any.isRequired,   // 'value' peut être de n'importe quel type
    index: PropTypes.number.isRequired // 'index' doit être un nombre
};

export default Enterprise;
export const config = {
    app: {
      // api_url: "http://192.168.1.170:9000/api",
      // api_url: "http://localhost:9000/api",
      api_url: "https://api.fntec.cm/api",
      // api_url: "http://testfntec-api.alshadowsgroup.com/api",
  
      mapbox_token: "pk.eyJ1Ijoid2lsZnJpZDIwMjMiLCJhIjoiY2xrMXJtdDF1MDhhZTNkcG9qb3RrcG5hayJ9.O2_u5z7Njds5oXV7Vv1b0w"
  
    },
  };
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKButton from "components/MKButton";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import { config } from "constants/config/config";

function Counters() {
  const { t } = useTranslation();
  const [statsEnterprises, setStatEnterprises] = useState({});
  const [expatsByNationality, setExpatsByNationality] = useState([]);
  const [statExpats, setStatExpats] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingE, setLoadingE] = useState(false);
  const [loadingEN, setLoadingEN] = useState(false);

  const fetchStatEnterprises = async () => {
    const params = {};
    params.fromArchive = false;
    setLoading(true);
    axios
      .get(`${config.app.api_url}/enterprises/stats-details`, { params },
        {
          headers: {
            // Authorization: authHeader(),
          }
        }
      )
      .then((res) => {
        setStatEnterprises(res?.data?.data);
        console.log("Enterprises DATA ", res?.data?.data);
      })
      .catch((err) => {
        console.log("Enterprises error ", err);
        setStatEnterprises([]);
      })
      .finally(() => setLoading(false));
  };

  const fetchStatExpats = async () => {
    const params = {};
    params.fromArchive = false;
    setLoadingE(true);
    axios
      .get(`${config.app.api_url}/employers/stats-details`, { params },
        {
          headers: {
            // Authorization: authHeader(),
          }
        }
      )
      .then((res) => {
        setStatExpats(res?.data?.data);
        console.log("employers DATA ", res?.data?.data);
      })
      .catch((err) => {
        console.log("employers error ", err);
        setStatExpats({});
      })
      .finally(() => setLoadingE(false));
  };

  const fetchExpatByNationality = async () => {
    const params = {};
    params.fromArchive = false;
    setLoadingEN(true);
    axios
      .get(`${config.app.api_url}/employers/stats-by-nationality`, { params },
        {
          headers: {
            // Authorization: authHeader(),
          }
        }
      )
      .then((res) => {
        setExpatsByNationality(res?.data?.data);
        console.log("Enterprises DATA ", res?.data?.data);
      })
      .catch((err) => {
        console.log("Enterprises error ", err);
        setExpatsByNationality([]);
      })
      .finally(() => setLoadingEN(false));
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchStatEnterprises();
      await fetchStatExpats();
      await fetchExpatByNationality();
    };

    fetchData();
  }, []);

  //FORMATAGE DES SALAIRES
  const formatNumber = (nombre) => {

    if (nombre != null && nombre != undefined) {
      return nombre.toLocaleString('us')
    }
    else {
      return 0
    }
  }

  return (
    <MKBox component="section"
      bgColor="#8FBC8F" variant="contained" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            {!loading && <DefaultCounterCard
              color={"white"}
              count={statsEnterprises?.total && formatNumber(statsEnterprises?.total)}
              suffix="+"
              title={t("Statistiques.enterprise.title")}
              description={t("Statistiques.enterprise.description")}
            />}
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            {!loadingE && statExpats?.total ? <DefaultCounterCard
              count={statExpats?.total}
              color={"white"}
              suffix="+"
              title={t("Statistiques.expatrie.title")}
              description={t("Statistiques.expatrie.description")}
            /> : <></>}
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            {!loadingEN && <DefaultCounterCard
              count={expatsByNationality?.length && formatNumber(expatsByNationality?.length)}
              color={"white"}
              suffix="+"
              title={t("Statistiques.nationalite.title")}
              description={t("Statistiques.nationalite.description")}
            />}
          </Grid>
        </Grid>
        <Grid sx={{ textAlign: "center" }}>
          <MKButton
            variant="contained"
            color="light"
            size="large"
            component="a"
            href="/stats"
            sx={{ mb: 2 }}
          >
            {t("Statistiques.bouton")}
          </MKButton>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
